<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      내 램 포인트<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_3">
      <div class="sect_txt">
        램에서 일반회원에게 지급하는 포인트는 바로 사용 가능합니 다. 램 포인트는
        램에서 상품/서비스 거래 시 사용할 수 있으나 현금으로 인출할 수 없으며
        회원 탈퇴 시 자동 소멸됩니다. (단위 : 포인트, 100포인트=1원 )
      </div>
      <div class="sect_box">
        <div class="list">
          <div class="left">현재 내 램 포인트</div>
          <div class="right">총 {{ won(totalPoint) }}P</div>
        </div>
        <!-- <div class="list">
          <div class="left">사용 가능한 포인트</div>
          <div class="right">총 5,020,000</div>
        </div> -->
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_4" v-if="listArray.length > 0">
      <div class="sect_sort">
        <select name="" v-model="sort" @change="getRammPoint(true)">
          <option value="latest">최신순</option>
          <option value="old">오래된순</option>
          <option value="high">고액순</option>
        </select>
      </div>
      <div class="sect_table">
        <table summary="">
          <colgroup>
            <col width="*" />
            <col width="80px" />
            <col width="80px" />
          </colgroup>
          <thead>
            <tr>
              <th>포인트 항목</th>
              <th>포인트</th>
              <th>날짜</th>
            </tr>
          </thead>
          <tbody v-if="listArray">
            <template v-for="list in listArray">
              <tr class="type_1" :key="`${list.emoney_seq}_first`">
                <td>{{ list.type_name }}</td>
                <td>{{ won(list.emoney) }}</td>
                <td>{{ list.regist_date.substr(2, 8).replace(/-/g, ".") }}</td>
              </tr>
              <tr
                :class="getClass(list.gb, list.memo_lang)"
                :key="`${list.emoney_seq}_second`"
              >
                <template v-if="list.gb === 'plus' && !list.memo_lang">
                  <td>
                    보낸 사람:
                    {{ getManager(list.manager_seq) }}
                  </td>
                  <td colspan="2">받은 사람 : {{ myInfo.nickname }}</td>
                </template>

                <td
                  colspan="3"
                  v-else-if="list.gd === 'plus' && list.memo_lang"
                >
                  사유 : {{ list.memo }} {{ list.memo_lang }}
                </td>
                <td colspan="3" v-else>사유 : {{ list.memo }}</td>
              </tr>
            </template>

            <!---->
          </tbody>
          <tbody v-else>
            <tr class="none_point">
              <td colspan="3">적립된 포인트가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="rn_more" v-if="totalPage > page">
      <router-link to="" @click.native="getRammPoint()"
        >{{ limit }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      sort: "latest",
      page: 0,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    //초기화
    this.$store.dispatch("mypage/clearRammPoint");
    this.getRammPoint(true);
  },
  computed: {
    ...mapState("mypage", [
      "result",
      "msg",
      "totalPoint",
      "listArray",
      "totalPage",
      "myInfo",
      "checkAuth",
      "limit",
    ]),
  },
  methods: {
    async getRammPoint(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("mypage/getRammPoint", {
        page: this.page,
        sort: this.sort,
        reset,
      });
      this.page++;
    },
    won(str) {
      return this.$won(str);
    },
    getClass(type, memo) {
      if (type === "minus") {
        return "type_3";
      } else if (type === "plus" && memo) {
        return "type_3";
      } else {
        return "type_2";
      }
    },
    getManager(type) {
      if (parseInt(type) === 0) {
        return "램";
      }
      return "램 관리자";
    },
  },
};
</script>

<style lang="scss" scoped>
.none_point {
  background: #eee;
  td {
    font-size: 12px;
    line-height: 16px;
    padding: 12px 0;
    vertical-align: middle;
    text-align: center;
  }
}
</style>
